import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Impressum = () => (
  <Layout>
    <SEO title="Impressum" />
    <div className="container mx-auto mt-48">
      <h1>Impressum</h1>
      <p>Felicia Rensner</p>
      <p>felicia.rensner@hotmail.de</p>
    </div>
  </Layout>
)

export default Impressum
